import styled from "styled-components";

const TimelineWrapper = styled.div`
  .timeline {
    position: relative;
    width: 860px;
    margin: 0px auto;
    padding: 20px;
    list-style-type: none;
  }
  @media (max-width: 860px) {
    .timeline {
      width: 100%;
      padding: 4em 0 1em 0;
    }
  }
  .timeline:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: " ";
    display: block;
    width: 6px;
    height: 100%;
    margin-left: -3px;
    background: #ededed;
    z-index: 5;
  }
  .timeline li {
    padding: 1em 0;
  }
  @media (max-width: 860px) {
    .timeline li {
      padding: 2em 0;
    }
  }
  .timeline li::after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .direction-l {
    position: relative;
    width: 400px;
    float: left;
    text-align: right;
  }
  @media (max-width: 860px) {
    .direction-l {
      float: none;
      width: 100%;
      text-align: center;
    }
  }
  .direction-l .flag {
  }
  .direction-l .flag:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-left-color: #e6e6e6;
    border-width: 8px;
    pointer-events: none;
  }
  @media (max-width: 860px) {
    .direction-l .flag:after {
      content: "";
      position: absolute;
      left: 50%;
      top: -8px;
      height: 0;
      width: 0;
      margin-left: -8px;
      border: solid transparent;
      border-bottom-color: white;
      border-width: 8px;
      pointer-events: none;
    }
  }
  .direction-l .time-wrapper {
    float: left;
  }
  @media (max-width: 860px) {
    .direction-l .time-wrapper {
      float: none;
    }
  }

  .direction-r {
    position: relative;
    width: 400px;
    float: right;
  }
  @media (max-width: 860px) {
    .direction-r {
      float: none;
      width: 100%;
      text-align: center;
    }
  }
  .direction-r .flag {
  }
  .direction-r .flag:after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: #e6e6e6;
    border-width: 8px;
    pointer-events: none;
  }
  @media (max-width: 860px) {
    .direction-r .flag:after {
      content: "";
      position: absolute;
      left: 50%;
      top: -8px;
      height: 0;
      width: 0;
      margin-left: -8px;
      border: solid transparent;
      border-bottom-color: white;
      border-width: 8px;
      pointer-events: none;
    }
  }
  .direction-r .flag:before {
    left: -40px;
  }
  .direction-r .time-wrapper {
    float: right;
  }
  @media (max-width: 860px) {
    .direction-r .time-wrapper {
      float: none;
    }
  }

  .flag-wrapper {
    position: relative;
    display: inline-block;
    text-align: center;
  }
  @media (max-width: 860px) {
    .flag-wrapper {
      text-align: center;
    }
  }
  .flag-wrapper .flag {
    font-size: 2em;
    font-family: montserrat, sans-serif;
    position: relative;
    display: inline;
    padding: 6px 10px;
    font-weight: 500;
    text-align: left;
  }
  @media (max-width: 860px) {
    .flag-wrapper .flag {
      background: white;
      z-index: 15;
    }
  }

  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: 50%;
    right: -40px;
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    margin-top: -10px;
    background: #fff;
    border-radius: 10px;
    border: 4px solid #e6e6e6;
    z-index: 10;
  }
  @media (max-width: 860px) {
    .direction-l .flag:before,
    .direction-r .flag:before {
      position: absolute;
      top: -30px;
      left: 50%;
      content: " ";
      display: block;
      margin-left: -10px;
    }
  }

  .time-wrapper {
    display: inline;
    line-height: 1em;
    font-size: 0.66666em;
    color: #dbdbdb;
    vertical-align: middle;
  }
  @media (max-width: 860px) {
    .time-wrapper {
      display: block;
      position: relative;
      margin: 4px 0 0 0;
      z-index: 14;
    }
  }
  .time-wrapper .time {
    display: inline-block;
    padding: 4px 6px;
  }

  .desc {
    margin: 1em 0.75em 0 0;
    font-size: 0.9em;
    font-style: italic;
    line-height: 1.5em;
    a {
      font-family: "montserrat", sans-serif;
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-decoration: none;
      color: #292929;
      text-decoration: none;
      background-color: #d6c3c7;
      padding: 12px;
      span {
        margin: 10px;
      }
    }
    a:hover {
      color: white;
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -moz-transition: all 0.15s cubic-bezier(0.46, 0.01, 0.14, 1.35);
      -o-transition: all 0.15s cubic-bezier(0.46, 0.01, 0.14, 1.35);
      -webkit-transition: all 0.15s cubic-bezier(0.46, 0.01, 0.14, 1.35);
      transition: all 0.15s cubic-bezier(0.46, 0.01, 0.14, 1.35);
      background-color: #292929;
    }
  }

  @media (max-width: 860px) {
    .desc {
      position: relative;
      margin: 1em 1em 0 1em;
      padding: 1em;
      z-index: 15;
    }
  }
`;

export default TimelineWrapper;
