import React from "react";
import Fade from "react-reveal/Fade";
import { IoLogoWhatsapp } from "react-icons/io";

import {
  WhatsAppWrapper,
  WhatsAppTitle,
  WhatsAppSubtitle,
  WhatsAppButton,
} from "./style";

type WhatsAppProps = {};

const WhatsApp: React.FunctionComponent<WhatsAppProps> = () => {
  return (
    <WhatsAppWrapper>
      <Fade right>
        <WhatsAppSubtitle>Hagamos de tu matri un éxito.</WhatsAppSubtitle>
        <WhatsAppTitle>¿Quieres saber si tenemos tu fecha libre?</WhatsAppTitle>
      </Fade>
      <a href="https://bit.ly/385sl0x" rel="noreferrer" target="_blank">
        <a
          href="https://bit.ly/385sl0x"
          class="btn"
          rel="noreferrer"
          target="_blank"
        >
          <span>ENVÍANOS UN WHATSAPP</span>
          <IoLogoWhatsapp />
        </a>
      </a>
    </WhatsAppWrapper>
  );
};

export default WhatsApp;
