import styled from "styled-components";

const BlogPostsWrapper = styled.div`
  margin: 0 auto;
  padding: 0 75px;
  position: relative;
  @media only screen and (max-width: 600px) {
    padding: 10px 25px;
  }
`;
export const PostRow = styled.div`
  margin: 0 -15px;
  @media (max-width: 990px) {
    margin: 0 -10px;
  }
`;

export const PostCol = styled.div`
  margin-bottom: 50px;
  width: 33.33333%;
  float: left;
  padding: 0 15px;
  @media (max-width: 990px) {
    padding: 0 10px;
  }
  @media (max-width: 767px) {
    width: 50%;
  }
  @media (max-width: 575px) {
    width: 100%;
    margin-bottom: 10px;
    padding: 0;
  }
`;

export default BlogPostsWrapper;
