import styled from "styled-components";
import WhatsAppBG from "../../../images/background_02.jpg";

export const WhatsAppWrapper = styled.div`
  margin: auto;
  padding: 90px;
  background-color: #292929;
  color: #292929;
  background-image: url(${WhatsAppBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3px;
  margin-top: 120px;
  min-height: 632px;

  @media (max-width: 990px) {
    margin-top: 90px;
    padding: 25px;
  }
  @media (max-width: 600px) {
    margin-top: 30px;
    padding: 90px 25px;
    background-position: left;
  }

  .btn {
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    background-color: transparent;
    padding: 12px 32px 10px;
    font-weight: 700;
    -webkit-transition: 0.35s ease;
    transition: 0.35s ease;
    color: #292929;
    min-width: 140px;
    border: 2px solid #292929;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 0;
    font-size: 16px;
    letter-spacing: 0.1em;
    overflow: hidden;
    position: relative;
  }
  .btn:hover {
    color: #fff;
  }
  .btn:before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 50%;
    -webkit-transition: 0.35s ease;
    transition: 0.35s ease;
    background-color: #292929;
    -webkit-transform: translate(-50%, 0) skew(25deg);
    transform: translate(-50%, 0) skew(25deg);
  }
  .btn:hover:before {
    width: 150%;
  }
  .btn span {
    position: relative;
    z-index: 1;
    padding: 10px;
  }
`;

export const WhatsAppTitle = styled.div`
  width: 418px;
  min-height: 250px;
  line-height: 72px;
  font-size: 72px;
  letter-spacing: -2px;
  margin: 30px 0;
  position: relative;
  font-weight: 700;
  @media only screen and (max-width: 600px) {
    font-size: 56px;
    width: 360px;
    line-height: 56px;
  }
`;

export const WhatsAppSubtitle = styled.div`
  position: relative;
  line-height: 43px;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -1px;
  @media only screen and (max-width: 600px) {
    font-size: 28px;
  }
`;
