import React from "react";
import { Link } from "gatsby";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import TimelineWrapper from "./style";

type TimelineProps = {};

const Timeline: React.FunctionComponent<TimelineProps> = () => {
  return (
    <TimelineWrapper>
      <ul class="timeline">
        <li>
          <div class="direction-r">
            <div class="flag-wrapper">
              <span class="flag">Música de Fondo</span>
              <span class="time-wrapper">
                <span class="time">01 hora - 1 1/2 horas</span>
              </span>
            </div>
            <div class="desc">
              <p>
                Desde que empiezan a llegar los invitados hasta que llegan los
                novios.
              </p>
              <Link to="/musica/timeline-musica-de-fondo/">
                <IoIosArrowDroprightCircle />
                <span>Sugerencias</span>
              </Link>
            </div>
          </div>
        </li>
        <li>
          <div class="direction-l">
            <div class="flag-wrapper">
              <span class="flag">Entrada de Novios</span>
              <span class="time-wrapper">
                <span class="time">40 - 50 segundos</span>
              </span>
            </div>
            <div class="desc">
              <p>
                Se reproduce durante unos 40-50 segundos aprox al entrar ustedes
                y al darle una vuelta a la pista de baile hasta estar en
                posición para el primer baile.
              </p>
              <Link to="/musica/timeline-entrada-de-novios/">
                <IoIosArrowDroprightCircle />
                <span>Sugerencias</span>
              </Link>
            </div>
          </div>
        </li>
        <li>
          <div class="direction-r">
            <div class="flag-wrapper">
              <span class="flag">Baile Familiar (Vals)</span>
              <span class="time-wrapper">
                <span class="time">2 - 6 minutos</span>
              </span>
            </div>
            <div class="desc">
              <p>Generalmente un vals.</p>
              <Link to="/musica/timeline-baile-familiar/">
                <IoIosArrowDroprightCircle />
                <span>Sugerencias</span>
              </Link>
            </div>
          </div>
        </li>
        <li>
          <div class="direction-l">
            <div class="flag-wrapper">
              <span class="flag">Baile de Novios</span>
              <span class="time-wrapper">
                <span class="time">2 minutos</span>
              </span>
            </div>
            <div class="desc">
              <p>Elección particular de los novios.</p>
              <Link to="/musica/timeline-baile-de-novios/">
                <IoIosArrowDroprightCircle />
                <span>Sugerencias</span>
              </Link>
            </div>
          </div>
        </li>
        <li>
          <div class="direction-r">
            <div class="flag-wrapper">
              <span class="flag">Almuerzo / Cena</span>
              <span class="time-wrapper">
                <span class="time">45 minutos</span>
              </span>
            </div>
            <div class="desc">
              <p>
                Latin Pop suave, se puede bailar pero no tan estridente para los
                comensales.
              </p>
              <Link to="/musica/timeline-almuerzo-cena/">
                <IoIosArrowDroprightCircle />
                <span>Sugerencias</span>
              </Link>
            </div>
          </div>
        </li>
        <li>
          <div class="direction-l">
            <div class="flag-wrapper">
              <span class="flag">Inicio de Fiesta</span>
              <span class="time-wrapper">
                <span class="time"></span>
              </span>
            </div>
            <div class="desc">
              <p>
                La canción del momento, para levantar a todos de sus asientos.
              </p>
              <Link to="/musica/timeline-inicio-de-fiesta/">
                <IoIosArrowDroprightCircle />
                <span>Sugerencias</span>
              </Link>
            </div>
          </div>
        </li>
        <li>
          <div class="direction-r">
            <div class="flag-wrapper">
              <span class="flag">Fiesta</span>
              <span class="time-wrapper">
                <span class="time">4 horas</span>
              </span>
            </div>
            <div class="desc">
              <p>
                La lectura de pista es lo que mejor funciona, y un playlist de
                las infaltables de los novios es últil para que no dejen de
                bailar.
              </p>
              <Link to="/musica/timeline-fiesta/">
                <IoIosArrowDroprightCircle />
                <span>Sugerencias</span>
              </Link>
            </div>
          </div>
        </li>
        <li>
          <div class="direction-l">
            <div class="flag-wrapper">
              <span class="flag">Bouquet</span>
              <span class="time-wrapper">
                <span class="time">3 minutos</span>
              </span>
            </div>
            <div class="desc">
              <p>Uno, dos o hasta tres bouquets, lo tenemos bajo control.</p>
              <Link to="/musica/timeline-bouquet/">
                <IoIosArrowDroprightCircle />
                <span>Sugerencias</span>
              </Link>
            </div>
          </div>
        </li>

        <li>
          <div class="direction-r">
            <div class="flag-wrapper">
              <span class="flag">Hora Loca</span>
              <span class="time-wrapper">
                <span class="time">45 min - 1 hora</span>
              </span>
            </div>
            <div class="desc">
              <p>
                Trabajamos con la música de tu proveedor o hacemos nuestra
                propuesta dentro de tus parámetros.
              </p>
              <Link to="/musica/timeline-hora-loca/">
                <IoIosArrowDroprightCircle />
                <span>Sugerencias</span>
              </Link>
            </div>
          </div>
        </li>
        <li>
          <div class="direction-l">
            <div class="flag-wrapper">
              <span class="flag">Tramo final</span>
              <span class="time-wrapper">
                <span class="time">2 - 2:30 horas</span>
              </span>
            </div>
            <div class="desc">
              <p>La hora del after.</p>
              <Link to="/musica/timeline-after/">
                <IoIosArrowDroprightCircle />
                <span>Sugerencias</span>
              </Link>
            </div>
          </div>
        </li>
      </ul>
    </TimelineWrapper>
  );
};

export default Timeline;
