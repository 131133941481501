import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { TestimoniosWrapper, SlideDiv } from "./style";
import Swiper from "react-id-swiper";

type TestimoniosProps = {};

const Testimonios: React.FunctionComponent<TestimoniosProps> = () => {
  const params = {
    grabCursor: true,
    slidesPerView: 1,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 980px
      980: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      // when window width is >= 1400px
      1400: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  const sliderData = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: modifiedTime, order: DESC }
        filter: { relativeDirectory: { eq: "testimonios" } }
      ) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fixed(width: 365) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  return (
    <TestimoniosWrapper>
      <Swiper {...params}>
        {sliderData.allFile.edges.map((file) => (
          <SlideDiv>
            <Img
              key={file.id}
              fixed={file.node.childImageSharp.fixed}
              alt={file.node.name}
            />
          </SlideDiv>
        ))}
      </Swiper>
    </TestimoniosWrapper>
  );
};
export default Testimonios;
