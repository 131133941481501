import React from "react";
import { StepsWrapper } from "./style";
import {
  BsCalendar,
  BsMusicNoteList,
  BsCardChecklist,
  BsSpeaker,
  BsPen,
  BsFillPersonCheckFill,
  BsArrowRight,
} from "react-icons/bs";

type StepsProps = {};

const Steps: React.FunctionComponent<StepsProps> = () => {
  return (
    <StepsWrapper>
      <div class="steps">
        <div class="step">
          <BsCalendar size="43px" />
          <span>Reunión de Presentación</span>
          <div class="arrow">
            <BsArrowRight />
          </div>
        </div>
        <div class="step">
          <BsPen size="43px" />
          <span>Firma de Contrato</span>
          <div class="arrow">
            <BsArrowRight />
          </div>
        </div>
        <div class="step">
          <BsCardChecklist size="43px" />
          <span>Reunión de Protocolo</span>
          <div class="arrow">
            <BsArrowRight />
          </div>
        </div>
        <div class="step">
          <BsMusicNoteList size="43px" />
          <span>Armando de Protocolo</span>
          <div class="arrow">
            <BsArrowRight />
          </div>
        </div>
        <div class="step">
          <BsSpeaker size="43px" />
          <span>Instalación del Evento</span>
          <div class="arrow">
            <BsArrowRight />
          </div>
        </div>
        <div class="step">
          <BsFillPersonCheckFill size="43px" />
          <span>Supervisión del Evento</span>
          <div class="arrow last">
            <BsArrowRight />
          </div>
        </div>
      </div>
    </StepsWrapper>
  );
};
export default Steps;
