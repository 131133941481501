import React from "react";
import { VideoWrapper, VideoContainer } from "./style";

type VideoProps = {};

const Video: React.FunctionComponent<VideoProps> = () => {
  return (
    <VideoWrapper>
      <VideoContainer>
        <iframe
          src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fdjpaulperu%2Fvideos%2F701698793631441%2F&show_text=0&width=560"
          width="100%"
          height="100%"
          scrolling="no"
          frameborder="0"
          allowTransparency="true"
          allowFullScreen="true"
          style={{
            display: "block",
          }}
        ></iframe>
      </VideoContainer>
    </VideoWrapper>
  );
};

export default Video;
