import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { graphql, useStaticQuery } from "gatsby";
import { LightboxWrapper } from "./style";
import Img from "gatsby-image";

type LightboxProps = {};

const Lightbox: React.FunctionComponent<LightboxProps> = () => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    sourceIndex: 0,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      sourceIndex: number,
    });
  }
  const LightboxData = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "bodas" } }) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);
  let mydata: Array<String> = [];
  for (var i in LightboxData.allFile.edges) {
    mydata.push(LightboxData.allFile.edges[i].node.childImageSharp.fluid.src);
  }
  return (
    <LightboxWrapper>
      <FsLightbox
        toggler={lightboxController.toggler}
        sourceIndex={lightboxController.sourceIndex}
        sources={mydata}
      />
      <div class="masonry">
        {LightboxData.allFile.edges.map((file, index) => {
          return (
            <div
              class="masonry-brick"
              onClick={() => openLightboxOnSlide(index)}
            >
              <Img fluid={file.node.childImageSharp.fluid} />
            </div>
          );
        })}
      </div>
    </LightboxWrapper>
  );
};
export default Lightbox;
