import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Masonry from "react-masonry-component";
import MasonryCard from "../../../components/masonry-card/masonry-card";
import BlogPostsWrapper, { PostRow, PostCol } from "./style";

type PostsProps = {};

const Posts: React.FunctionComponent<PostsProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { tags: { eq: "matri" } } }
        limit: 3
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 300)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMM DD, YYYY")
              title
              description
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 570) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const Posts = Data.allMarkdownRemark.edges;

  return (
    <BlogPostsWrapper>
      <PostRow>
        <Masonry className="showcase">
          {Posts.map(({ node }: any) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <PostCol key={node.fields.slug}>
                <MasonryCard
                  title={title}
                  image={
                    node.frontmatter.cover == null
                      ? null
                      : node.frontmatter.cover.childImageSharp.fluid
                  }
                  url={"/musica/" + node.fields.slug}
                  date={node.frontmatter.date}
                  tags={node.frontmatter.tags}
                />
              </PostCol>
            );
          })}
        </Masonry>
      </PostRow>
    </BlogPostsWrapper>
  );
};

export default Posts;
