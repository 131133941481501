import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Bodas from "../containers/bodas";

type BodasPageProps = {};

const BodasPage: React.FunctionComponent<BodasPageProps> = (props) => {
  return (
    <Layout>
      <SEO
        title="Bodas, Tips, Testimonios, Mixes, Alquiler de Equipos de Sonido y Luces"
        description="Dj Paul dedica todo su potencial para tener la pista llena en tu fiesta de matrimonio, brindamos los servicios de alquiler de equipos de sonido, luces inteligentes, tips para la música de la boda, mixes y testimonios."
      />

      <Bodas />
    </Layout>
  );
};

export default BodasPage;
