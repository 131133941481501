import styled from "styled-components";

export const TestimoniosWrapper = styled.div`
  background-color: #d6c3c7;
  background-position: center;
  margin: auto;
  vertical-align: middle;
  .swiper-wrapper {
  }
  .swiper-pagination {
    width: auto;
    left: 70px;
    color: #ffffff;
    font-weight: 700;
    .swiper-pagination-current {
      font-size: 52px;
    }
    .swiper-pagination-total {
      font-size: 22px;
    }
  }
`;

export const SlideDiv = styled.div`
  margin: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 580px;
  .gatsby-image-wrapper {
    -webkit-box-shadow: 0px 0px 16px 8px rgba(41, 41, 41, 0.5);
    -moz-box-shadow: 0px 0px 16px 8px rgba(41, 41, 41, 0.5);
    box-shadow: 0px 0px 16px 8px rgba(41, 41, 41, 0.5);
    border-radius: 5px;
  }
  .swiper-slide {
    width: auto;
  }
  vertical-align: middle;
`;
