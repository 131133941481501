import styled from "styled-components";

export const VideoWrapper = styled.div``;

export const VideoContainer = styled.div`
  width: 60%;
  height: 500px;
  margin: 30px auto;
  @media (max-width: 1275px) {
    height: 400px;
  }
  @media (max-width: 990px) {
    width: 80%;
  }
  @media (max-width: 575px) {
    width: 90%;
    height: 200px;
  }
`;
