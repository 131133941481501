import styled from "styled-components";

export const StepsWrapper = styled.div`
  background-position: center;
  min-height: 160px;
  position: relative;
  margin: 0 auto;
  @media (max-width: 575px) {
    min-height: 400px;
  }
  .steps {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .step {
    text-transform: uppercase;
    color: #292929;
    padding: 30px;
    display: inline-block;
    width: 16.666%;
    @media (max-width: 960px) {
      width: 33.333%;
    }
    span {
      display: block;
    }
    .arrow {
      position: relative;
      top: -50px;
      left: 100%;
    }
  }
  .last {
    color: #ededed;
  }
`;
