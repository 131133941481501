import styled from "styled-components";
import WhatsAppBG from "../../images/background_01.jpg";

export const BodasWrapper = styled.div`
  iframe {
    width: 87%;
    margin-left: 75px;
  }
`;

export const BodasTitle = styled.h2``;

export const BodasDescription = styled.div`
  padding: 75px 90px;
  @media only screen and (max-width: 992px) {
    padding: 0 15px;
  }
  @media only screen and (max-width: 600px) {
    padding: 15px 0;
  }
  .columns {
    align-items: center;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      display: block;
    }
    .column {
      display: inline-block;
      width: 50%;
      align-items: center;
      @media only screen and (max-width: 600px) {
        display: block;
        width: 100%;
      }
    }
  }

  .description {
    color: #292929;
    margin-left: 16.66666667%;
    padding-right: 60px;
    p {
      vertical-align: middle;
      color: #767676;
    }
  }
  .foto {
    position: relative;
    text-align: center;
    width: 460px;
    @media only screen and (max-width: 992px) {
      width: 340px;
    }
    @media only screen and (max-width: 600px) {
      width: 355px;
      margin: 0 auto;
    }
    img {
      border-radius: 5px;
    }
    .title {
      position: absolute;
      bottom: 28px;
      left: 28px;

      h1 {
        color: #fff;
        font-weight: 900;
        font-size: 2vh;
        line-height: 0.85;
        text-shadow: 0 0.375vh 0.75vh rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

export const Servicios = styled.div`
  margin: 60px 0 0;
  background-color: #292929;
  background-image: url(${WhatsAppBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  min-height: 620px;
  font-family: "montserrat", sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 25px;
  color: #292929;
  position: relative;
  @media (max-width: 990px) {
  }
  @media (max-width: 575px) {
  }

  .row {
    right: 0px;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 75px;
    height: 300px;
    width: 340px;
    background-color: #fff;
    padding: 0 20px;
  }
  .gatsby-image-wrapper {
    padding: 0;
    width: 160px;
  }
  .col {
    padding: 12px;
  }
`;

export const BodasSubTitle = styled.h3``;

export const BodasImage = styled.div`
  position: relative;
`;

export const BannerTitle = styled.div`
  position: absolute;
  bottom: 180px;
  left: 120px;
  color: #fff;
  @media only screen and (max-width: 992px) {
    bottom: 100px;
    right: 220px;
  }
  @media only screen and (max-width: 600px) {
    bottom: 30px;
    right: 100px;
  }
  .main-title {
    text-shadow: 0 0.375vh 0.75vh rgba(0, 0, 0, 0.1);
    font-weight: 900;
    font-size: 120px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: -3px;
    @media only screen and (max-width: 992px) {
      font-size: 100px;
    }
    @media only screen and (max-width: 600px) {
      font-size: 48px;
      letter-spacing: -1px;
    }
  }
  .main-title:after {
    content: "";
    width: 80px;
    height: 1px;
    background: #fff;
    display: block;
    margin: 8px 0;
  }
  span {
    display: inline-block;
    font-size: 22px;
    font-weight: 300;
  }
`;

export const Hashtag = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  text-align: center;
  background-color: #757575;
  font-weight: 300;
  font-family: "montserrat", sans-serif;
  font-size: 80px;
  @media (max-width: 1000px) {
    font-size: 60px;
    height: 360px;
  }
  @media (max-width: 800px) {
    font-size: 50px;
    height: 300px;
  }
  @media (max-width: 630px) {
    font-size: 40px;
    height: 360px;
  }
  @media (max-width: 520px) {
    font-size: 24px;
    height: 300px;
    p {
      font-size: 12px;
    }
  }

  .inner-div {
    line-height: 10px;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #ffffff;
    a {
      color: #ffffff;
      :hover {
        color: #292929;
      }
    }
    p {
      font-weight: 700;
    }
  }
`;
