import React from "react";
import {
  BodasWrapper,
  BodasImage,
  BodasTitle,
  BodasSubTitle,
  BodasDescription,
  Hashtag,
  Servicios,
  BannerTitle,
} from "./style";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import Timeline from "./timeline";
import Posts from "./posts";
import Contact from "./contact";
import WhatsApp from "./whatsapp";
import Lightbox from "./lightbox";
import Testimonios from "./testimonios";
import Zigzag from "../../components/zigzag/zigzag";
import Video from "./video";
import Steps from "./steps";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

type BodasProps = {};

const Bodas: React.FunctionComponent<BodasProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/dj-paul-boda-boda-banner.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      slogan: file(absolutePath: { regex: "/slogan-bodas.png/" }) {
        childImageSharp {
          fixed(width: 713) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      djs: file(absolutePath: { regex: "/dj-paul-dj-g.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      logo: file(absolutePath: { regex: "/logo-dj-paul-luces-y-sonido.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <BodasWrapper>
      <BodasImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
        <BannerTitle>
          <h1 class="main-title">BODAS</h1>
          <Image
            fixed={Data.slogan.childImageSharp.fixed}
            alt="la música para el día más importante"
          />
        </BannerTitle>
      </BodasImage>
      <BodasDescription>
        <div class="columns">
          <div class="column">
            <div class="description">
              <p>
                Cuando se trata de armar un matrimonio inolvidable y que todo el
                mundo tenga que hablar de este, se necesita un efectivo manejo
                de la pista de baile, para esto, Dj Paul dedica todo su
                potencial para tener la pista llena desde la primera canción.
                Después de explorar diferentes tipos de públicos en la
                discotecas limeñas cada semana, con toda la artillería musical
                en la punta de los dedos, tus canciones favoritas sumando los
                clásicos como ingrediente escencial, y con un ritmo en las
                mezclas que no puede venir de nadie más que de Dj Paul.
              </p>

              <p>
                Dj Paul trabaja de la mano con un staff de Djs, principalmente
                con su brazo derecho Dj G, quienes trabajan con la misma música
                y estilo, dando una propuesta estandarizada en la pista de
                baile.
              </p>
              <p>
                <a href="https://www.zankyou.com.pe/f/dj-paul-sutcliffe-464278">
                  <img
                    width="100"
                    alt="Recomendado por Zankyou"
                    src="https://asset1.zankyou.com/img/sections/badgets/zk_recommend/circle/badge_green_pe.png"
                  />
                </a>
              </p>
            </div>
          </div>
          <div class="column">
            <div class="foto">
              <Image fluid={Data.djs.childImageSharp.fluid} alt="author" />
              <div class="title">
                <h1>Dj Paul y Dj G</h1>
              </div>
            </div>
          </div>
        </div>
      </BodasDescription>
      <Fade right>
        <BodasTitle>proceso</BodasTitle>
        <BodasSubTitle>desde hoy hasta el gran día</BodasSubTitle>
      </Fade>
      <Steps />
      <Servicios>
        <div class="row">
          <Image fluid={Data.logo.childImageSharp.fluid} alt="author" />
          <div class="col">SONIDO PROFESIONAL</div>
          <div class="col">LUCES INTELIGENTES</div>
        </div>
      </Servicios>
      <Hashtag>
        <div class="inner-div">
          <p>REVISA TODOS NUESTROS EVENTOS EN INSTAGRAM</p>
          <Zoom>
            <a href="https://bit.ly/2NzEYaK" target="_target">
              👉🏻#DJPAULUCESYSONIDO
            </a>
          </Zoom>
        </div>
      </Hashtag>
      <Fade left>
        <BodasTitle>en acción</BodasTitle>
        <BodasSubTitle>en una boda en Arequipa 🏔</BodasSubTitle>
      </Fade>
      <Video />
      <Zigzag />
      <WhatsApp />
      <Fade left>
        <BodasTitle id="tips">tips</BodasTitle>
        <BodasSubTitle>para el protocolo de la boda 👰🏻🤵🏻</BodasSubTitle>
      </Fade>
      <Timeline />
      <Fade left>
        <BodasTitle>fotos</BodasTitle>
        <BodasSubTitle>de algunas de nuestras bodas</BodasSubTitle>
      </Fade>
      <Lightbox />
      <Fade left>
        <BodasTitle>mixes</BodasTitle>
        <BodasSubTitle>
          un extracto de la propuesta para tu fiesta
        </BodasSubTitle>
      </Fade>
      <Posts />
      <Fade left>
        <BodasTitle>testimonios</BodasTitle>
        <BodasSubTitle>de nuestros felices recién casados</BodasSubTitle>
      </Fade>
      <iframe
        sandbox="allow-same-origin allow-scripts"
        src="https://www.zankyou.com.pe/iframe/reviews-widget/wervice/464278"
        frameborder="0"
      ></iframe>
      <Testimonios />
      <Fade left>
        <BodasTitle>consulta</BodasTitle>
        <BodasSubTitle>
          tu fecha tentativa para enviarte el presupuesto
        </BodasSubTitle>
      </Fade>
      <Contact />
    </BodasWrapper>
  );
};

export default Bodas;
